<template>
	<div id="cuerpo">
		<v-layout v-if="estilo === 'row2cols'" wrap>
			<div class="bloque col2row1" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
			<div class="bloque col2row1" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
		</v-layout>
		<v-layout v-else-if="estilo === 'row3cols'" wrap>
			<div class="bloque col3row1" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
			<div class="bloque col3row1" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
			<div class="bloque col3row1" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
		</v-layout>
		<v-layout v-else-if="estilo === 'row2cols3L'" wrap>
			<div class="bloque col2row1" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
			<div class="col2tmpl">
				<div class="bloque col2row2" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
				<div class="bloque col2row2" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
			</div>
		</v-layout>
		<v-layout v-else-if="estilo === 'row2cols3R'" wrap>
			<div class="col2tmpl">
				<div class="bloque col2row2" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
				<div class="bloque col2row2" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
			</div>
			<div class="bloque col2row1" @click="fnSelMe($event)" @contextmenu="fnCntmn($event)" :id="fnRamdomCod()"></div>
		</v-layout>

		<v-menu
			v-model="stContext"
			:position-x="x"
			:position-y="y"
			rounded
			absolute
			offset-y>
			<v-list dense>
				<v-list-item
					v-for="(item, index) in menu"
					:key="index"
					@click="fnMenuAccion(item.accion)"
					link>
					<v-list-item-icon>
						<v-icon v-text="item.icono"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="item.texto"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: 'rowtmpl',
	props: {
		estilo: String
	},
	data() {
		return {
			menu: [
				{ texto: 'Delete image', icono: 'mdi-delete', accion: 'delete' },
				{ texto: 'Remove row', icono: 'mdi-table-row-remove', accion: 'remove' }
			],
			stContext: false,
			x: 0,
			y: 0,
			currObj: null
		}
	},
	methods: {
		fnSelMe(e) {
			if(e.target.tagName !== 'DIV') return
			if(e.target.style.backgroundColor === '') {
				e.target.style.backgroundColor = '#546E7A'
				this.$emit('updateid', e.target.id)
			}else{
				e.target.style.backgroundColor = ''
				this.$emit('updateid', '')
			}
			e.stopPropagation()
		},
		fnRamdomCod() {
			return Math.random().toString().substr(2, 12)
		},
		fnCntmn(e) {
			e.preventDefault()
			this.stContext = false
			this.x = e.clientX
			this.y = e.clientY
			this.$nextTick(() => {
				this.currObj = 	e.target
				this.stContext = true
			})
		},
		fnMenuAccion(accion) {
			var target = this.currObj
			switch(accion) {
				case 'delete':
				console.info(target.parentElement.classList.contains("layout"))
				if(target.parentElement.classList.contains("layout")) break
					target.parentElement.parentElement.style.backgroundColor = ''
					target.parentElement.remove()
					break
				case 'remove':
					if(target.parentElement.classList.contains("layout")) {
						target.parentElement.remove()
					}else{
						target.parentElement.parentElement.parentElement.remove()
					}
					break
			}
			this.currObj = null
		}
	}
}
</script>

<style scoped>
.baseRow {
	display: flex;
	width: 100%;
	clear: both;
}
.bloque {
	display: flex;
	align-items: center;
	justify-content: center;
	border: dotted 1px gray;
	background: url('~@/assets/icon-image-.png') center center no-repeat;
}
.col2row1 {
	width: 351px;
	min-height: 351px;
	background-color: #ECEFF1;
}
.col3row1 {
	width: 234px;
	min-height: 195px;
	background-color: #ECEFF1;
}
.col2row2 {
	width: 351px;
	height: 200px;
	background-color: #ECEFF1;
}
.col2tmpl {
	display: flex;
	flex-flow: column;
	width: 351px;
	height: 400px;
}
</style>