<template>
	<div id="newsletter">
		<h2 class="text-titulo section-title">{{ $store.state.titulofix }}</h2>
		<v-spacer class="my-3"></v-spacer>
		<v-container fluid>
			<v-row>
				<!-- Izquierdo -->
				<v-col cols="12" md="6" class="blue-grey lighten-5">
					<v-row dense>
						<v-col md="auto">
							<v-select
								:items="fuentes"
								v-model="stFont"
								style="width:150px"
								label="Font">
							</v-select>
						</v-col>
						<v-col md="auto">
							<v-select 
								:items="brands" 
								v-model="marca"
								style="width:200px"
								label="Brands">
							</v-select>
						</v-col>
						<v-col md="auto">
							<v-text-field
								v-model="buscar"
								label="Search"
								:disabled="!marca"
								v-on:keyup.enter="fnSearch">
							</v-text-field>
						</v-col>
					</v-row>
					<v-divider></v-divider>
					<v-data-table
						:items="listado"
						:headers="cabecera"
						:loading="stLoading"
						:items-per-page="limit"
						:expanded.sync="abiertos"
						disable-sort
						show-expand
						single-expand
						hide-default-footer>
						<template v-slot:[`item.preview`]="{ item }">
							<v-img :src="item.image" ref="minimg" aspect-ratio="0.8" style="max-width: 150px; border: thin solid #37474F;"></v-img>
						</template>
						<template v-slot:[`item.info`]="{ item }">
							<h3 class="text--h5 text-titulo">{{ item.title }} <v-chip color="blue" dark small>{{ item.type }}</v-chip></h3>
							<v-row>
								<v-col cols="12" md="4">
									<v-text-field
										readonly
										prefix="$"
										color="error"
										label="Base"
										v-model="item.compAt"></v-text-field>
								</v-col>
								<v-col cols="12" md="4">
									<v-text-field
										prefix="$"
										color="success"
										label="Final"
										v-model="item.price"
										v-on:keyup.enter="fnUpdPrice(item.id, item.price)"></v-text-field>
								</v-col>
								<v-col cols="12" md="4">
									<v-text-field
										readonly
										suffix="%"
										label="Discount"
										:value="fnCalculate(item.compAt, item.price)"></v-text-field>
								</v-col>
							</v-row>
							<v-chip-group column>
								<v-chip 
									class="mx-1" 
									color="blue-grey darken-3" 
									small 
									outlined
									label
									v-for="stock in item.stock" 
									:key="stock.title">{{ stock.title }}: {{ stock.qty }}</v-chip>
							</v-chip-group>
							<v-chip-group column>
								<v-chip 
									class="mx-1" 
									color="blue-grey darken-3" 
									small 
									dark
									v-for="chip in item.tags" 
									:key="chip">{{ chip }}</v-chip>
							</v-chip-group>
							<div class="text-uppercase">{{ fnTimeZone(item.date) }}</div>
						</template>

						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length" style="padding:0;">
								<v-card class="pa-5 blue-grey lighten-2" flat>
									<v-row>
										<v-col cols="12" md="auto">
											<v-card class="mx-auto" max-width="350" style="min-width: 100px">
												<vue-cropper
													ref="cropper"
													:src="item.image"
													:aspect-ratio="ancho/alto">
												</vue-cropper>
											</v-card>
										</v-col>
										<v-col cols="12" md="auto">
											<v-row>
												<v-col>
													<v-btn
														class="red lighten-2"
														@click="fnCropme">
														<v-icon left>mdi-crop</v-icon>Crop
													</v-btn>
												</v-col>
												<v-col>
													<v-btn
														class="blue lighten-2"
														:disabled = "imgBlock === ''"
														@click="fnCopy(item.handle)">
														<v-icon left>mdi-debug-step-over</v-icon>Append
													</v-btn>
												</v-col>
												
											</v-row>
											<v-row no-gutters>
												<v-col>
													<v-switch
														v-model="newflag"
														hint="New"
														:persistent-hint="true"
														color="red"
													>
													</v-switch>
												</v-col>
												<v-col>
													<v-switch
														v-model="special"
														hint="Special"
														:persistent-hint="true"
														color="red"
													>
													</v-switch>
												</v-col>
												<v-col>
													<v-switch
														v-model="priceflag"
														hint="Price"
														:persistent-hint="true"
														color="blue"
													>
													</v-switch>
												</v-col>
												<v-col>
													<v-switch
														@change="fnSquare"
														v-model="sqrflag"
														hint="Square"
														:persistent-hint="true"
														color="teal"
													>
													</v-switch>
												</v-col>
												<v-col>
													<v-switch
														@change="fnHalf"
														v-model="hiflag"
														hint="½ Height"
														:persistent-hint="true"
														color="purple"
													>
													</v-switch>
												</v-col>
												<v-col>
													<v-switch
														@change="fnThird"
														v-model="wiflag"
														hint="⅓ Width"
														:persistent-hint="true"
														color="green"
													>
													</v-switch>
												</v-col>
											</v-row>
											<v-row no-gutters>
												<v-col>
													<div class="preview" ref="preview" :style="{ 'background-image': 'url('+canvimg+')', width: ancho + 'px', height: alto + 'px' }">
														<div v-if="newflag" class="newribbon" :style="{ 'background-image': 'url('+require('../assets/new2.png')+')' }"></div>
														<div v-if="special" class="spcribbon" :style="{ 'background-image': 'url('+require('../assets/buy3get1.png')+')' }"></div>
														<div v-if="!priceflag" class="precios" :style="{ 'font-family': stFont, 'font-size': stFontSize }"><div class="slash"></div>${{ item.compAt }} / <div style="color: #00E676">${{ item.price }}</div></div>
													</div>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
									
								</v-card>
							</td>
						</template>
						<template v-slot:footer>
							<v-row class="ma-2">
								<v-col>
									<v-select :items="paginacion" @change="fnLoad()" v-model="limit" label="Items" style="max-width:100px;"></v-select>
								</v-col>
								<v-col class="text-right">
									<v-btn :disabled="stPrev" @click="fnChange('prev')" outlined color="dark" large class="mx-2"><v-icon left>mdi-chevron-left</v-icon>Prev</v-btn>
									<v-btn :disabled="stNext" @click="fnChange('next')" outlined color="dark" large>Next<v-icon right>mdi-chevron-right</v-icon></v-btn>
								</v-col>
							</v-row>
						</template>

					</v-data-table>
				</v-col>

				<v-spacer></v-spacer>

				<!-- Derecho -->
				<v-col cols="12" md="6" class="blue-grey lighten-5 px-2">
					<v-row dense>
						<v-col md="auto">
							<v-dialog v-model="wnClear" width="500">
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="red darken-2" large dark @click="wnClear = true" v-bind="attrs" v-on="on">
										<v-icon left>mdi-close</v-icon>Clear
									</v-btn>
								</template>
								<v-card>
									<v-card-title><v-icon left>mdi-alert</v-icon>Warning</v-card-title>
									<v-card-text>
										Do you really want to delete all the generated images?
									</v-card-text>
									<v-divider></v-divider>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="wnClear = false">Cancel</v-btn>
										<v-btn color="primary" text @click="fnClear">Ok</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</v-col>
						<v-col md="auto">
							<v-btn color="blue darken-1" class="mx-1" large dark @click="fnPlus2Col"><v-icon left>mdi-table-row-plus-after</v-icon>2 Cols</v-btn>
						</v-col>
						<v-col md="auto">
							<v-btn color="blue darken-2" class="mx-1" large dark @click="fnPlus3Col"><v-icon left>mdi-table-row-plus-after</v-icon>3 Cols</v-btn>
						</v-col>
						<v-col md="auto">
							<v-btn color="blue darken-3" class="mx-1" large dark @click="fnPlusSpcL"><v-icon left>mdi-view-grid</v-icon>L Spc</v-btn>
						</v-col>
						<v-col md="auto">
							<v-btn color="blue darken-4" class="mx-1" large dark @click="fnPlusSpcR"><v-icon left>mdi-view-grid</v-icon>R Spc</v-btn>
						</v-col>
						<v-col md="auto">
							<v-btn color="green darken-2" class="mx-1" large dark @click="fnDownload"><v-icon left>mdi-download</v-icon>Download</v-btn>
						</v-col>
					</v-row>
					<br />
					<v-divider></v-divider>
					<v-card flat class="pa-2 blue-grey lighten-3 pizarron">
						<v-layout v-for="(tipo, index) in bloques" :key="index" ref="pizarra" wrap>
							<row-tmpl :estilo="tipo" @updateid="fnUpdateid"></row-tmpl>
						</v-layout>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import axios from "axios"
import { DateTime } from "luxon"
import { saveAs } from 'file-saver'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import JsZip from 'jszip'
import * as htmlToImage from 'html-to-image'

import RowTmpl from '@/components/RowTemplate.vue'

export default {
	name: 'musnewletter',
	metaInfo: {
		title: 'MUS Newsltter'
	},
	components: {
		VueCropper,
		RowTmpl
	},
	data() {
		return {
			fuentes: [ 'Dosis', 'Advent Pro', 'Pompiere', 'Oswald', 'Comfortaa' ],
			brands: [],
			cabecera: [
				{ text: 'Image', value: 'preview', width: '20%' },
				{ text: 'Info' , value: 'info'}
			],
			listado: [],
			abiertos: [],
			paginacion: [ 5, 10, 30 ],
			stLoading: false,
			limit: 10,
			stFont: 'Comfortaa',
			stFontSize: '35px',
			marca: null,
			buscar: null,
			wnClear: false,
			cursor: null,
			stPrev: true,
			stNext: true,
			canvimg: null,
			dialogo: false,
			newflag: false,
			special: false,
			priceflag: false,
			hiflag: false,
			wiflag: false,
			sqrflag: false,
			ancho: 351,
			alto: 400, //390
			fuente: 35,
			bloques: [],
			imgBlock: ''
		}
	},
	mounted() {
		axios.post('/mus-brands')
			.then(response => this.brands = response.data)
		this.$store.state.titulofix = 'Newsletter Maker MUS'
	},
	watch: {
		imgBlock: function(nuevo) {
			if(nuevo !== '') {
				const children = this.$el.querySelectorAll('div.bloque');
				children.forEach( item => { 
					if(item.id !== nuevo) {
						item.style.backgroundColor = ''
					}
				})
			}
		}
	},
	methods: {
		fnLoad() {
			this.stLoading = true
			axios.post('/mus-newsbrand', { brand: this.marca, limit: this.limit, cursor: this.cursor, dir: this.dir, word: this.buscar })
				.catch( () => {
					this.$store.state.mensaje = 'Error: server side error. Wait a moment and try again.'
					this.$store.state.estado = 'error'
					this.$store.state.snackme = true
					this.$errorsnd()
					this.stLoading = false
				} )
				.then(response => {
					this.listado = response.data.list
					this.stPrev = !response.data.prev
					this.stNext = !response.data.next
					this.stLoading = false
				})
		},
		fnSearch() {
			this.abiertos = []
			this.cursor = null
			this.canvimg = null
			this.fnLoad()
		},
		async fnCopy(handle) {
			const ele = this.$refs.preview
			await htmlToImage.toJpeg(ele, { quality: 0.90 })
				.then( dataurl => {
					let img = new Image()
					let envase = document.createElement('div')
					envase.style.cssText = 'width: ' + this.ancho + 'px; height: ' + this.alto + 'px'
					img.src = dataurl
					img.setAttribute('data-handle', handle)
					envase.appendChild(img)
					document.getElementById(this.imgBlock).innerHTML = ''
					document.getElementById(this.imgBlock).appendChild(envase)
					this.imgBlock = ''
				})

			this.abiertos = []
			this.canvimg = null
		},
		fnChange(dir) {
			if(dir == 'next') {
				const len = Object.keys(this.listado).length;
				this.cursor = this.listado[len-1].cursor;
			}else{
				this.cursor = this.listado[0].cursor;
			}
			this.dir = dir;
			this.abiertos = []
			this.listado = []
			this.fnLoad();
		},
		fnCropme() {
			this.canvimg = this.$refs.cropper.getCroppedCanvas().toDataURL()
		},
		fnDownload() {
			const fecha = DateTime.fromISO(DateTime.local(), { zone: "America/New_York" }).toSQLDate()
			const brand = this.marca.toLowerCase().substr(0, 3)
			var zip = new JsZip()
			var temp = "<div style='width: 100%'><a href='https://www.mensunderwearstore.com/collections/yyy'>\n\t<img src='https://stevenevens.com/img/xxx' border='0' style='display: block; width: 100%' /></a>\n</div>\n"
			var oBloques = this.$el.querySelectorAll('div.bloque')
			var tagOpen = 0
			oBloques.forEach((obj, inx) => {
				let nombre = brand + (inx+1) + "_" + fecha + ".jpg"
				let imagen = obj.firstElementChild.firstElementChild
				if(obj.className.includes('col2row1')) {
					if(tagOpen === 0) {
						temp += "<ul style='list-style-type: none; margin: 0; padding: 0;'>\n\t<li style='display: table-cell; width: 50%;'>\n\t\t<a href='https://www.mensunderwearstore.com/products/"+imagen.getAttribute('data-handle')+"'><img src='https://stevenevens.com/img/"+nombre+"' border='0' style='display: block; width: 100%' /></a>\n\t</li>\n"
						tagOpen = 1
					}else if(tagOpen === 1) {
						temp += "\t<li style='display: table-cell; width: 50%;'>\n\t\t<a href='https://www.mensunderwearstore.com/products/"+imagen.getAttribute('data-handle')+"'><img src='https://stevenevens.com/img/"+nombre+"' border='0' style='display: block; width: 100%' /></a>\n\t</li>\n</ul'>\n"
						tagOpen = 0
					}
				}
				if(obj.className.includes('col3row1')) {
					switch(tagOpen) {
						case 0:
							temp += "<ul style='list-style-type: none; margin: 0; padding: 0;'>\n\t<li style='display: table-cell; width: 33.3%;'>\n\t\t<a href='https://www.mensunderwearstore.com/products/"+imagen.getAttribute('data-handle')+"'><img src='https://stevenevens.com/img/"+nombre+"' border='0' style='display: block; width: 100%' /></a>\n\t</li>\n"
							tagOpen = 2
							break
						case 1:
							temp += "\t<li style='display: table-cell; width: 33.3%;'>\n\t\t<a href='https://www.mensunderwearstore.com/products/"+imagen.getAttribute('data-handle')+"'><img src='https://stevenevens.com/img/"+nombre+"' border='0' style='display: block; width: 100%' /></a>\n\t</li>\n</ul'>\n"
							tagOpen = 0
							break
						case 2:
							temp += "\t<li style='display: table-cell; width: 33.3%;'>\n\t\t<a href='https://www.mensunderwearstore.com/products/"+imagen.getAttribute('data-handle')+"'><img src='https://stevenevens.com/img/"+nombre+"' border='0' style='display: block; width: 100%' /></a>\n\t</li>\n"
							tagOpen = 1
							break
					}
				}

				let datos = imagen.src.split('base64,')
				zip.folder(this.marca.toLowerCase() + '-' + fecha).file(nombre, datos[1], {base64: true})
			})
			zip.folder(this.marca.toLowerCase() + '-' + fecha).file('tmpl.htm', temp);
			zip.generateAsync({type:"blob"}).then(function(content) {
				saveAs(content, "example.zip")
			})
		},
		fnClear() {
			this.bloques = []
			this.wnClear = false
			this.imgBlock = ''
		},
		fnUpdPrice(id, price) {
			let ids = [id]
			axios.post('/mus-massupdate', {ids: ids, price: price})
				.then(() => {
					this.$successsnd()

				})
		},
		fnCalculate(compAt, price) {
			return Math.round((compAt - price) / compAt * 100);
		},
		fnTimeZone(val) {
			const fecha = DateTime.fromISO(val, { zone: "America/New_York" })
			return fecha.toRelativeCalendar()
		},
		fnSquare(flag) {
			this.wiflag = false
			this.hiflag = false
			if(flag) {
				this.ancho = 351
				this.alto = 351
			}else{
				this.ancho = 351
				this.alto = 400
			}
			this.$refs.cropper.setAspectRatio(this.ancho/this.alto)
		},
		fnHalf(flag) {
			this.sqrflag = false
			if(flag) {
				this.alto = 200
			}else{
				this.alto = 400
			}
			this.$refs.cropper.setAspectRatio(this.ancho/this.alto)
		},
		fnThird(flag) {
			this.sqrflag = false
			if(flag) {
				this.ancho = 234
				this.stFontSize = '25px'
			}else{
				this.ancho = 351
				this.stFontSize = '35px'
			}
			this.$refs.cropper.setAspectRatio(this.ancho/this.alto)
		},
		fnPlus2Col() {
			this.bloques.push('row2cols')
		},
		fnPlus3Col() {
			this.bloques.push('row3cols')
		},
		fnPlusSpcL() {
			this.bloques.push('row2cols3L')
		},
		fnPlusSpcR() {
			this.bloques.push('row2cols3R')
		},
		fnUpdateid(val) {
			this.imgBlock = val
		}
	}
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Dosis&family=Comfortaa&family=Advent+Pro&family=Pompiere&display=swap');
.preview {
	position: relative;
	background-size: cover;
	background-color: darkgrey;
	box-sizing: border-box;
	border: 2px solid rgba(0, 0, 0, 0.9);
	overflow: hidden;
}
.precios {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 45px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	line-height: 100%;
}
.precios > .slash {
	height: 3px;
	background-color: rgba(237,237,237,0.95);
	position: absolute;
	top: 42%;
	left: 20%;
	width: 25%;
	transform: rotate(-18deg);
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
}
.peque {
	font-size: 35px !important;
}
.newribbon {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 90px;
	height: 90px;
	background-size: cover;
}
.spcribbon {
	position: absolute;
	top: 2px;
	right: 2px;
	width: 80px;
	height: 80px;
	background-size: cover;
}
.text--h5 {
	font-size: 1.5rem;
}
.pizarron {
	min-height: 400px;
}
</style>